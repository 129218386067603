import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ModalFooter } from 'components/AppModal/styles';
import { svgs } from 'assets';
import { Text } from 'components/common/Styles';
import { ModalButton, WrapperAddEmployeeModal } from 'pages/Employee/components/style';

import Modal from 'react-bootstrap/Modal';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';

import {
  getSupervisorEmployeeLeave,
  updateEmployeeLeaveForSupervisor,
} from 'redux/actions/adminAppraisal';

import PageContainer from '../../components/common/PageContainer';
// import { Wrapper, ModalTextarea, Text, Error } from './styles';
import {
  Wrapper,
  Section,
  DetailContainer,
  LabelValueWrapper,
  GroupWrapper,
  Label,
  Value,
  ModalTextarea,
  Error,
  NotesSection,
  ActionButton,
} from './styles';

const Client = () => {
  const { Id } = useParams();
  const dispatch = useDispatch();

  const [addResponse, setAddResponse] = useState(false);
  const [detail, setDetail] = useState({});

  const fetchEmployeeLeave = async () => {
    const response = await dispatch(getSupervisorEmployeeLeave(Id));
    setDetail(response);
  };

  useEffect(() => {
    fetchEmployeeLeave();
    // eslint-disable-next-line
  }, [dispatch]);

  const statusChecker = detail?.client_status;
  const onSubmit = async (values, { resetForm }) => {
    const { comment, status } = values;
    const payload = { client_notes: comment, client_status: status };
    await dispatch(updateEmployeeLeaveForSupervisor(detail.id, payload));
    await fetchEmployeeLeave();
    setAddResponse(false);
    resetForm();
  };

  return (
    <>
      {!detail?.id ? (
        <Section>No Request Found</Section>
      ) : (
        <PageContainer>
          <Wrapper status={detail?.status}>
            <h2>Client Leave Review</h2>
            {/* Personal Info Section */}
            <Section>
              <h3>Personal Information</h3>
              <DetailContainer>
                <GroupWrapper>
                  <LabelValueWrapper>
                    <Label>First Name:</Label>
                    <Value>{detail?.employee?.user_info?.firstname}</Value>
                  </LabelValueWrapper>
                  <LabelValueWrapper>
                    <Label>Last Name:</Label>
                    <Value>{detail?.employee?.user_info?.lastname}</Value>
                  </LabelValueWrapper>
                </GroupWrapper>

                <GroupWrapper>
                  <LabelValueWrapper>
                    <Label>Email:</Label>
                    <Value>{detail?.employee?.user_info?.email}</Value>
                  </LabelValueWrapper>
                  <LabelValueWrapper>
                    <Label>Gender:</Label>
                    <Value>{detail?.employee?.user_info?.gender}</Value>
                  </LabelValueWrapper>
                </GroupWrapper>

                <GroupWrapper>
                  <LabelValueWrapper>
                    <Label>Phone Number:</Label>
                    <Value>{detail?.employee?.user_info?.phone}</Value>
                  </LabelValueWrapper>
                  <LabelValueWrapper>
                    <Label>Address:</Label>
                    <Value>{detail?.employee?.current_address || 'N/A'}</Value>
                  </LabelValueWrapper>
                </GroupWrapper>
              </DetailContainer>
            </Section>

            {/* Job Info Section */}
            <Section>
              <h3>Job Information</h3>
              <DetailContainer>
                <GroupWrapper>
                  {' '}
                  <LabelValueWrapper>
                    <Label>Company:</Label>
                    <Value>{detail?.employee?.company_info?.name || 'N/A'}</Value>
                  </LabelValueWrapper>
                  <LabelValueWrapper>
                    <Label>Job Role:</Label>
                    <Value>{detail?.employee?.jobrole_info?.name || 'N/A'}</Value>
                  </LabelValueWrapper>
                </GroupWrapper>

                <GroupWrapper>
                  <LabelValueWrapper>
                    <Label>Branch Name:</Label>
                    <Value>{detail?.employee?.branch_info?.name || 'N/A'}</Value>
                  </LabelValueWrapper>
                  <LabelValueWrapper>
                    <Label>Branch Address:</Label>
                    <Value>{detail?.employee?.branch_info?.address.toUpperCase() || 'N/A'}</Value>
                  </LabelValueWrapper>
                </GroupWrapper>
                <GroupWrapper>
                  <LabelValueWrapper>
                    <Label>Department:</Label>
                    <Value>{detail?.employee?.department_info?.name || 'N/A'}</Value>
                  </LabelValueWrapper>
                </GroupWrapper>
              </DetailContainer>
            </Section>

            {/* Leave Info Section */}
            <Section>
              <h3>Leave Details</h3>

              <DetailContainer>
                <GroupWrapper>
                  <LabelValueWrapper>
                    <Label>Leave Address:</Label>
                    <Value>{detail?.leave_address || 'N/A'}</Value>
                  </LabelValueWrapper>
                  <LabelValueWrapper>
                    <Label>Leave Type:</Label>
                    <Value>{detail?.leave_type?.name || 'N/A'}</Value>
                  </LabelValueWrapper>
                </GroupWrapper>

                <GroupWrapper>
                  <LabelValueWrapper>
                    <Label>Leave Days:</Label>
                    <Value>{detail?.num_of_days || 'N/A'}</Value>
                  </LabelValueWrapper>
                  <LabelValueWrapper>
                    <Label>Leave Starts:</Label>
                    <Value>{moment(detail?.start_date).format('MMM Do YY')}</Value>
                  </LabelValueWrapper>
                </GroupWrapper>

                <GroupWrapper>
                  <LabelValueWrapper>
                    <Label>Leave Ends:</Label>
                    <Value>{moment(detail?.end_date).format('MMM Do YY')}</Value>
                  </LabelValueWrapper>

                  <LabelValueWrapper>
                    <Label>Leave Ends:</Label>
                    <Value>{moment(detail?.end_date).format('MMM Do YY')}</Value>
                  </LabelValueWrapper>
                </GroupWrapper>

                <GroupWrapper>
                  <LabelValueWrapper>
                    <Label>Branch Name:</Label>
                    <Value>{detail?.branch_name.toUpperCase() || '--'}</Value>
                  </LabelValueWrapper>
                  <LabelValueWrapper>
                    <Label>Branch Address:</Label>
                    <Value>{`${detail?.branch_address.toUpperCase() || '--'}, ${detail?.branch_state.toUpperCase() || '--'}`}</Value>
                  </LabelValueWrapper>
                </GroupWrapper>
                <GroupWrapper>
                  <>
                    {detail?.handover_document && (
                      <LabelValueWrapper>
                        <Label>Handover Document:</Label>
                        <a
                          href={detail?.handover_document || false}
                          download
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <button
                            type="button"
                            className="btn d-flex justify-content-between"
                          >
                            <span className="d-flex align-items-center">
                              <img src={svgs.DOC} alt="arrow-left" className="mr-1" />
                              <Text color="#1A21BA" size="12px">
                                Handover Document
                              </Text>
                            </span>
                            <img src={svgs.CHEVRON_LEFT} alt="arrow-left" />
                          </button>
                        </a>
                      </LabelValueWrapper>
                    )}
                  </>
                </GroupWrapper>

                {(detail?.status === 'ACCEPTED' || detail?.status === 'REJECTED') && (
                  <LabelValueWrapper>
                    <Label>Status:</Label>
                    <Value className={`status ${detail?.status?.toLowerCase()}`}>
                      {detail?.status === 'ACCEPTED' ? 'Accepted' : 'Rejected'}
                    </Value>
                  </LabelValueWrapper>
                )}
              </DetailContainer>
            </Section>

            {/* Relief Officer Details */}
            <>
              {detail?.relief_officer_needed && (
                <Section>
                  <h3>Relief Officer Details</h3>
                  <DetailContainer>
                    <LabelValueWrapper>
                      <Label>External Relief Officer Required:</Label>
                      <Value>{detail?.relief_officer_needed ? 'Yes' : 'No'}</Value>
                    </LabelValueWrapper>
                    <>
                      <GroupWrapper>
                        <LabelValueWrapper>
                          <Label>Relief Officer Name:</Label>
                          <Value>{detail?.relief_officer_name}</Value>
                        </LabelValueWrapper>
                        <LabelValueWrapper>
                          <Label>Relief Officer Email:</Label>
                          <Value>{detail?.relief_officer_email}</Value>
                        </LabelValueWrapper>
                      </GroupWrapper>
                      <GroupWrapper>
                        <LabelValueWrapper>
                          <Label>Relief Officer Phone:</Label>
                          <Value>{detail?.relief_officer_phone}</Value>
                        </LabelValueWrapper>
                        <>
                          {detail?.relief_officer_cv && (
                            <LabelValueWrapper>
                              <Label>Relief Officer CV:</Label>
                              <a
                                href={detail?.relief_officer_cv || false}
                                download
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <button
                                  type="button"
                                  className="btn d-flex justify-content-between"
                                >
                                  <span className="d-flex align-items-center">
                                    <img
                                      src={svgs.DOC}
                                      alt="arrow-left"
                                      className="mr-1"
                                    />
                                    <Text color="#1A21BA" size="12px">
                                      Relief Officer CV
                                    </Text>
                                  </span>
                                  <img src={svgs.CHEVRON_LEFT} alt="arrow-left" />
                                </button>
                              </a>
                            </LabelValueWrapper>
                          )}
                        </>
                      </GroupWrapper>
                    </>
                  </DetailContainer>
                </Section>
              )}
            </>

            {/* Supervisor Details */}
            <Section>
              <h3>Supervisor Details</h3>
              <DetailContainer>
                <GroupWrapper>
                  <LabelValueWrapper>
                    <Label>Line Manager Name:</Label>
                    <Value>{detail?.line_manager1_name || 'N/A'}</Value>
                  </LabelValueWrapper>
                  <LabelValueWrapper>
                    <Label>Line Manager Email:</Label>
                    <Value>{detail?.line_manager1_email || 'N/A'}</Value>
                  </LabelValueWrapper>
                </GroupWrapper>
                <GroupWrapper>
                  <LabelValueWrapper>
                    <Label>Line Manager Comment:</Label>
                    <Value>{detail?.line_manager1_comments || 'N/A'}</Value>
                  </LabelValueWrapper>
                </GroupWrapper>
                {detail?.line_manager2_name && (
                  <>
                    <GroupWrapper>
                      <LabelValueWrapper>
                        <Label>Line Manager Name:</Label>
                        <Value>{detail?.line_manager2_name || 'N/A'}</Value>
                      </LabelValueWrapper>
                      <LabelValueWrapper>
                        <Label>Line Manager Email:</Label>
                        <Value>{detail?.line_manager2_email || 'N/A'}</Value>
                      </LabelValueWrapper>
                    </GroupWrapper>
                    <GroupWrapper>
                      <LabelValueWrapper>
                        <Label>Line Manager Comment:</Label>
                        <Value>{detail?.line_manager2_comments || 'N/A'}</Value>
                      </LabelValueWrapper>
                    </GroupWrapper>
                  </>
                )}
              </DetailContainer>
            </Section>

            {/* Job Info Section */}
            <Section>
              <h3>Reviewed By</h3>
              <DetailContainer>
                <GroupWrapper>
                  <LabelValueWrapper>
                    <Label>Name:</Label>
                    <Value>{`${detail?.approved_by?.firstname} ${detail?.approved_by?.lastname} `}</Value>
                  </LabelValueWrapper>
                  <LabelValueWrapper>
                    <Label>Email:</Label>
                    <Value>{detail?.approved_by?.email || 'N/A'}</Value>
                  </LabelValueWrapper>
                </GroupWrapper>

                <GroupWrapper>
                  <LabelValueWrapper>
                    <Label>Comment:</Label>
                    <Value>{`${detail?.employer_comments}`}</Value>
                  </LabelValueWrapper>
                </GroupWrapper>
              </DetailContainer>
            </Section>

            {detail?.client_notes && (
              <NotesSection>
                <Label>Client Note</Label>
                <p>{detail?.client_notes}</p>
              </NotesSection>
            )}

            <ActionButton
              disabled={statusChecker === 'APPROVED'}
              onClick={() => setAddResponse(true)}
            >
              Comment
            </ActionButton>

            <WrapperAddEmployeeModal
              show={addResponse}
              onHide={() => {
                setAddResponse(false);
              }}
              animation={false}
            >
              <Formik
                initialValues={{
                  comment: '',
                  status: '',
                }}
                validationSchema={Yup.object({
                  comment: Yup.string().required('Please Add your Comment'),
                })}
                onSubmit={onSubmit}
              >
                {({
                  isSubmitting,
                  values,
                  handleChange,
                  handleSubmit,
                  touched,
                  handleBlur,
                  errors,
                  resetForm,
                  setFieldValue,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="detail_to_wrap">
                      <Modal.Header
                        closeButton
                        style={{
                          boxShadow: '0px 4px 20px rgba(199, 223, 241, 0.25',
                        }}
                      >
                        <b> Comment </b>
                      </Modal.Header>
                      <div>
                        <div className="form_grid form-21 m-3">
                          <ModalTextarea
                            name="comment"
                            placeholder="Please provide the comment here."
                            type="textarea"
                            onBlur={handleBlur}
                            value={values.comment}
                            onChange={handleChange}
                          />
                          <div className="d-flex justify-content-center align-items-center">
                            <p className="scorecard-modal-accept__text">
                              Are you sure you want to submit these comment?
                            </p>
                          </div>
                          {touched.comment && errors.comment ? (
                            <Error>{errors.comment}</Error>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <ModalFooter>
                      <ModalButton
                        type="submit"
                        disabled={statusChecker === 'DECLINED' || isSubmitting}
                        onClick={() => {
                          setFieldValue('status', 'DECLINED');
                          handleSubmit();
                        }}
                      >
                        Reject
                      </ModalButton>
                      <ModalButton
                        className="btn-2"
                        action="action"
                        type="submit"
                        disabled={isSubmitting}
                        onClick={() => {
                          setFieldValue('status', 'APPROVED');
                          handleSubmit();
                        }}
                      >
                        Accept
                      </ModalButton>
                    </ModalFooter>
                  </form>
                )}
              </Formik>
            </WrapperAddEmployeeModal>
          </Wrapper>
        </PageContainer>
      )}
    </>
  );
};

export default Client;
