/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable guard-for-in */
import React, { useState, useEffect } from 'react';
import { decodeToken } from 'utils/auth';
import MenuBar from 'components/common/MenuBar';
import { useSelector, useDispatch } from 'react-redux';
import { getEachUser, updateUser } from 'redux/actions/profile';
import { ToastError, ToastSuccess } from 'components/common/Toast';
import { capitalize } from 'utils/customHooks';
import {
  ProfileImageSection,
  PersonalInformation,
  Image,
  ProfileText,
  FileInput,
  FileSelector,
} from './styles';
import { svgs } from '../../assets';
import PersonalInfoForm from './PersonalInfoForm';
import { useToast } from 'context/toastContext';

const Employee = () => {
  const dispatch = useDispatch();
  const toast = useToast();
  const [errorToastMessage, setErrorToastMessage] = useState('');
  const [errorToastOpen, setErrorToastOpen] = useState(false);
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [fileData, setFileData] = useState('');
  const [file, setFile] = useState('');
  const formData = new FormData();

  const user = decodeToken(localStorage.getItem('accessToken'));
  const { loading, data, error, success } = useSelector((state) => state.profile);
  let listOfErrors = [];

  useEffect(() => {
    if (data.length === 0) {
      dispatch(getEachUser(user?.user_id));
    }
  }, [data.length, dispatch, user.user_id]);

  useEffect(() => {
    if (data) {
      setFileData(data?.image);
    }
  }, [data]);

  const generateError = () => {
    if (Array.isArray(error)) {
      listOfErrors = [...error];
    }

    if (typeof error === 'object') {
      const arry = [];
      for (const propName in error) {
        arry.push(`${capitalize(propName) } ${ error[propName]}`);
        listOfErrors = [...arry];
      }
    } else {
      listOfErrors.push(error);
    }

    return listOfErrors.map((item, index) => (
      <p className="col-12" key={index}>
        {item}
      </p>
    ));
  };

  useEffect(() => {
    if (success) {
      //setToastOpen(true);
      toast.success('Status Updated successfully');
      //setToastMessage('Status Updated successfully');
    }

    if (error) {
      setErrorToastMessage(generateError());
      setErrorToastOpen(true);
    }
    // eslint-disable-next-line
  }, [success, error, data]);

  const handleFile = (e) => {
    if (e.currentTarget.files) {
      const f = e.currentTarget.files[0];
      setFileData(URL.createObjectURL(f));
    }

    setFile(e.currentTarget.files[0]);
  };

  const sendProfileDetails = async (values) => {
    if (file) {
      formData.append('image', file);
      formData.append('firstname', values.fname);
      formData.append('lastname', values.lname);
      formData.append('email', values.email);
      formData.append('phone', values.phone);
    } else {
      formData.append('firstname', values.fname);
      formData.append('lastname', values.lname);
      formData.append('email', values.email);
      formData.append('phone', values.phone);
    }
await dispatch(updateUser(user?.user_id, formData));

  };

  return loading ? (
    <>
      <MenuBar title="Profile" />
      <div style={{ margin: '15% 50%' }}>Loading...</div>
    </>
  ) : (
    <>
      <MenuBar title="Profile" />
      <PersonalInformation>
        <ProfileImageSection>
          <Image>
            <img
              src={fileData || svgs.PROFILE_ICON}
              height="70px"
              width="70px"
              style={{ objectFit: 'cover' }}
              alt="profile"
            />
            {fileData && (
              <img
                src={svgs.close_button}
                alt="close"
                style={{ cursor: 'pointer' }}
                onClick={() => setFileData('')}
                className="close-img"
              />
            )}
          </Image>
          <ProfileText>
            <span>Edit profile photo</span>
            <span>Max file is 3MB</span>
          </ProfileText>
          <FileInput>
            <label htmlFor="profile">
              <FileSelector>Select Image</FileSelector>
              <input
                type="file"
                accept="image/jpeg, image/png, image/svg,image/jpg"
                onChange={(e) => handleFile(e)}
              />
            </label>
          </FileInput>
        </ProfileImageSection>
        <PersonalInfoForm user={data} sendProfileDetails={sendProfileDetails} />
      </PersonalInformation>

      <ToastError
        message={errorToastMessage}
        isOpen={errorToastOpen}
        close={() => {
          setErrorToastOpen(!errorToastOpen);
        }}
        setToastMessage={setToastMessage}
      />

      <ToastSuccess
        message={toastMessage}
        isOpen={toastOpen}
        close={() => {
          setToastOpen(!toastOpen);
        }}
      />
    </>
  );
};

export default Employee;
